import React, { Fragment} from "react";
import logomin_img from"./images/logomin.svg";
import {getMessage} from "./util";
import logo_sra from "./images/logo_sra.png";

const FooterNew =(props)=> {

    return (
        <Fragment>
            <div className="row justify-content-center EvColorBlue mesa-ayuda">
                <div className="row mesa-ayuda-content col-6 mt-4 mb-4 mx-0 justify-content-center EvColorBlue align-items-center h-100">
                    <div className="mesa-ayuda-img col-4 my-6 text-white text-right d-flex">
                        <img src={logomin_img} className="img-max-100" alt="footer"/>
                    </div>
                    <div className="mesa-ayuda-content col-md-6 col-8 my-4 mr-1 text-white font-weight-bold">
                        <p>{getMessage("mesa_ayuda_evoting", props.messages)}</p>
                        <p>{getMessage("correo", props.messages)}:
                            <a href="mailto:soporte@evoting.com"> {getMessage("correo_soporte", props.messages)}</a>
                        </p>
                        <p>{getMessage("telefono", props.messages)}:
                            <a href="https://wa.me/541151685716" target="_blank"> {getMessage("telefono_soporte", props.messages)}</a>
                        </p>
                    </div>
                </div>
                <div className="row mesa-ayuda-content col-6 mt-4 mb-4 mx-0 justify-content-center EvColorBlue align-items-center border-left border-light h-100">
                    <div className="mesa-ayuda-img col-4 my-6 text-white text-right d-flex">
                        <img className="img-max-100" alt="Logo-SRA" src={logo_sra}/>
                    </div>
                    <div className="mesa-ayuda-content col-md-6 col-8 my-4 text-white font-weight-bold">
                        <p>{getMessage("mesa_ayuda_sra", props.messages)}</p>
                        <p>{getMessage("correo", props.messages)}:
                            <a href="mailto:socios@sra.org.ar"> {getMessage("correo_soporte_sra", props.messages)}</a>
                        </p>
                        <p>{getMessage("telefono", props.messages)}:
                            <a href="https://wa.me/5491140551845" target="_blank"> {getMessage("telefono_soporte_sra", props.messages)}</a>
                        </p>
                    </div>
                </div>
            </div>
        </Fragment>

    );

}


export default FooterNew;